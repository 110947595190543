<template>
    <div class="inner">
        <div class="col left">
            <router-link :to="{ name: 'account' }" v-if="user && (product_data.product_settings.general.sso_force !== true || product_data.product_settings.general.accounts_locked !== true)">
                {{ $t("footer_account") }}
            </router-link>
            <a href="#_login" v-on:click="login()" v-if="!user && product_data.product_settings.general.sso_force !== true">
                {{ $t("footer_login") }}
            </a>
        </div>
        <div class="col right" v-if="product_data.product_settings.widget.branding">
            <a href="https://sleekplan.com?utm_source=widget&utm_medium=link&utm_campaign=public_branding&utm_id=branding" target="_blank">{{ $t("footer_sleekplan") }} <ion-icon name="ios-flash"></ion-icon></a>
        </div>
    </div>
</template>

<script>
export default {

    name: 'app-footer',


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        product_data() {
            return this.$store.getters.product_data;
        },

        user() {
            return this.$store.getters.user_data;
        },

    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  Do login
         */
        login( data ) {

            this.$store.dispatch( 'widget_user_check' );

        },

    }       

}
</script>